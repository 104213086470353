const app = angular.module('tickets', [])
    .config(["$locationProvider", "$interpolateProvider", function ($locationProvider, $interpolateProvider) {
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
        $interpolateProvider.startSymbol('{%');
        $interpolateProvider.endSymbol('%}');
    }]);

app.directive('stopccp', function () {
    return {
        scope: {},
        link: function (scope, element) {
            element.on('cut copy paste', function (event) {
                event.preventDefault();
            });
        }
    };
});

app.controller('MainCtrl', ["$scope", "$http", "$location", "$anchorScroll", function ($scope, $http, $location, $anchorScroll) {
    $scope.state = 1;
    $scope.tickets = window.tickets;
    $scope.form = {
        firstEmail: {
            value: '',
            error: false,
            errorMsg: '',
        },
        secondEmail: {
            value: '',
            error: false,
            errorMsg: '',
        }
    };
    $scope.agree = false;
    $scope.orderError = false;
    $scope.submitted = false;
    $scope.order = [];

    $scope.confirmRules = () => {
        $scope.state = 2;
        scrollTo('scrollEmail');
    };

    $scope.confirmEmail = () => {
        if (checkEmail()) {
            $scope.state = 3;
            scrollTo('scrollTickets');
        }
    };

    $scope.confirmOrder = ($event, data) => {
        let form = $event.target;
        $scope.submitted = true;
        $scope.orderError = false;
        if (checkOrder(data)) {
            let phone = getPhone();
            //$scope.order.order_email = $scope.form.firstEmail;
            $http.post('/buy', {
                'tickets': formatTickets($scope.order),
                order_email: $scope.form.firstEmail.value
            }).then((response) => {
                if (response.data.status === 1) {
                    form.setAttribute('action', 'https://pay.nashestvie.ru/create');
                    form.setAttribute('method', 'POST');
                    document.querySelector('[name="orderid"]').value = response.data.order.internal_order_id;
                    document.querySelector('[name="clientid"]').value = response.data.order.internal_order_id;
                    document.querySelector('[name="sum"]').value = response.data.order.sum;
                    document.querySelector('[name="phone"]').value = phone;
                    document.querySelector('[name="client_email"]').value = response.data.order.order_email;
                    document.querySelector('[name="cart"]').value = JSON.stringify(response.data.order.cart);
                    document.querySelector('[name="expiry"]').value = response.data.order.expiry;
                    form.submit();
                } else {
                    //alert('Не удалось оформить заказ, ' + response.data.msg);
                    $scope.orderError = response.data.msg;
                }
            }, (error) => {
                if (error.data.status === 0) {
                    $scope.orderError = error.data.msg;
                } else {
                    $scope.orderError = 'Ошибка оформления заказа!';
                }
            });
        }
    };

    $scope.increment = (ticket) => {
        if ($scope.order.length < 10) {
            let newTicket = angular.copy(ticket);
            $scope.order.push(newTicket);
        }
    };

    $scope.decrement = (ticket) => {
        for (let step = 1; step <= 2; step++) {
            for (let i = $scope.order.length - 1; i >= 0; i--) {
                if ($scope.order[i].id === ticket.id) {
                    if (step === 2 || checkFilling($scope.order[i])) {
                        $scope.order.splice(i, 1);
                        return true;
                    }
                }
            }
        }
    };

    let checkFilling = (ticket) => {
        if (ticket.type === 1) {
            if (typeof ticket.order === 'undefined') {
                return true;
            } else {
                let empty = true;
                for (let property in ticket.order) {
                    if (ticket.order.hasOwnProperty(property)
                        && typeof ticket.order[property] !== 'undefined'
                        && ticket.order[property].length > 0) {
                        empty = false;
                        break;
                    }
                }
                return empty;
            }
        }
        return true;
    };

    $scope.getCount = (ticket) => {
        let count = 0;

        $scope.order.forEach((item) => {
            if (item.id === ticket.id) {
                count++;
            }
        });

        return count;
    };

    $scope.getCost = () => {
        let cost = 0;

        $scope.order.forEach((item) => {
            cost += parseInt(item.price, 10);
        });

        return cost;
    };

    $scope.plural = (count, one, few, many) => {
        let firstDigit = Math.abs(count) % 10,
            twoFirstDigits = Math.abs(count) % 100;

        if (firstDigit === 1) {
            return one;
        } else if (firstDigit > 1 && firstDigit < 5 && (twoFirstDigits < 10 || twoFirstDigits > 19)) {
            return few;
        } else {
            return many;
        }
    };

    $scope.delete = (index) => {
        if (typeof $scope.order[index] !== 'undefined') {
            $scope.order.splice(index, 1);
        }
    };

    let checkOrder = (form) => {
        let reg = /.+@.+\..+/i;
        $scope.orderError = false;

        if ($scope.agree !== true) {
            $scope.orderError = 'Подтвердите корректность введеных данных.';
            return false;
        }

        if ($scope.form.firstEmail.value.length < 1) {
            $scope.orderError = 'Вы не указали основной E-mail.';
            return false;
        }

        if (form.$invalid === true) {
            $scope.orderError = 'Пожалуйста, заполните обязательные поля.';
            return false;
        }

        for (let i = 0; i < $scope.order.length; i++) {
            if ($scope.order[i].type === 1) {
                let undefined = typeof $scope.order[i].order === 'undefined';
                if (undefined || (!undefined && !reg.test($scope.order[i].order.email))) {
                    $scope.order[i].order.emailError = true;
                    $scope.orderError = "Вы указали неправильный E-mail.";
                } else {
                    $scope.order[i].order.emailError = false;
                }
            }
        }

        return $scope.orderError === false;
    };

    let checkEmail = () => {
        let reg = /.+@.+\..+/i;

        $scope.form.firstEmail.error = false;
        $scope.form.secondEmail.error = false;

        if ($scope.form.firstEmail.value.length < 1) {
            $scope.form.firstEmail.error = true;
            $scope.form.firstEmail.errorMsg = "Заполните, обязательное поле.";
        } else if (!reg.test($scope.form.firstEmail.value)) {
            $scope.form.firstEmail.error = true;
            $scope.form.firstEmail.errorMsg = "Неправильный E-mail.";
        }

        if ($scope.form.secondEmail.value.length < 1) {
            $scope.form.secondEmail.error = true;
            $scope.form.secondEmail.errorMsg = "Заполните, обязательное поле.";
        } else if (!reg.test($scope.form.secondEmail.value)) {
            $scope.form.secondEmail.error = true;
            $scope.form.secondEmail.errorMsg = "Неправильный E-mail.";
        } else if ($scope.form.firstEmail.value !== $scope.form.secondEmail.value) {
            $scope.form.secondEmail.error = true;
            $scope.form.secondEmail.errorMsg = "E-mail не совпадает.";
        }

        return $scope.form.firstEmail.error === false && $scope.form.secondEmail.error === false;
    };

    let formatTickets = (tickets) => {
        tickets.map((ticket) => {
            if (typeof ticket.order !== 'undefined') {
                for (let property in ticket.order) {
                    if (ticket.order.hasOwnProperty(property)) {
                        if (ticket.order[property].length > 1) {
                            ticket[property] = ticket.order[property];
                        }
                    }
                }
            }
        });
        return tickets;
    };

    let scrollTo = (hash) => {
        $location.hash(hash);
        $anchorScroll();
    };

    let getPhone = () => {
        for (let i = 0; i < $scope.order.length; i++) {
            if (typeof $scope.order[i].order !== 'undefined') {
                if (typeof $scope.order[i].order.phone !== 'undefined' && $scope.order[i].order.phone.length > 0) {
                    return $scope.order[i].order.phone;
                }
            }
        }

        return '';
    };

}]);
